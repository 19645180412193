<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="info" class="mr-2" @click="createEditShow()">
          <v-icon class="mr-1">mdi-plus</v-icon>
          New Individual
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert outlined dismissible icon="mdi-account" prominent type="info">
        <div>
          <h3>UnStruct automates the process of involving the right individuals in an incident response process.</h3>
            By assigning specific terms, incident types, and priorities to individuals, UnStruct can:
          <ul>
            <li>Instantly add them to an incident, ensuring rapid response.</li>
            <li>Suggest reaching out to them based on the incident's context and severity.</li>
          </ul>
        </div>
      </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
              <v-card class="ma-2 elevation-3">
                <v-card-title>
                  <v-icon large left>mdi-human-greeting</v-icon>
                  {{ item.name }}
                  <v-spacer></v-spacer>
                  <individual :individual="item" />
                </v-card-title>

                <v-card-subtitle class="mb-2 mt-3">
                  <v-chip v-if="item.filters.length > 0" color="success" dark>
                    <v-icon left>mdi-filter</v-icon>
                    Filters: {{ item.filters.length }}
                  </v-chip>
                </v-card-subtitle>

                <v-card-text>
                  <div class="my-2" v-for="filter in item.filters" :key="filter.id">
                    <search-filter :filter="filter" />
                  </div>
                  <v-chip v-if="item.is_active" class="my-2 mr-2">
                    <v-icon left color="blue">mdi-check</v-icon>
                    Active
                  </v-chip>
                  <v-chip v-if="!item.is_active" class="my-2 mr-2">
                    <v-icon left color="grey">mdi-close</v-icon>
                    Disabled
                  </v-chip>
                  <v-chip v-if="item.is_external" class="my-2 mr-2">
                    <v-icon left color="error">mdi-open-in-new</v-icon>
                    External
                  </v-chip>
                  <v-chip v-if="!item.is_external" class="my-2 mr-2">
                    <v-icon left color="success">mdi-account-circle</v-icon>
                    Internal
                  </v-chip>
                </v-card-text>

                <v-card-actions class="justify-end">
                  <v-btn icon @click="createEditShow(item)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="createEditShow(item)">
                        <v-list-item-title>View / Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="removeShow(item)">
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-data-table
            :items="items"
            :server-items-length="total"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="descending"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/individual/DeleteDialog.vue"
import NewEditSheet from "@/individual/NewEditSheet.vue"
import Individual from './Individual.vue'
import SearchFilter from "@/search/SearchFilter.vue"

export default {
  name: "IndividualTable",

  components: {
    DeleteDialog,
    NewEditSheet,
    SettingsBreadcrumbs,
    Individual,
    SearchFilter,
  },
  data() {
    return {

    headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Company", value: "company", sortable: true },
        { text: "Active", value: "is_active", sortable: true },
        { text: "External", value: "is_external", sortable: true },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
    }
  },

  computed: {
    ...mapFields("individual", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("individual", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>
