<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container>
    <v-row no-gutter>
      <v-combobox
        :items="items"
        :label="label"
        :loading="loading"
        :menu-props="{ maxHeight: '400' }"
        :search-input.sync="search"
        @update:search-input="getFilteredData({ q: $event })"
        chips
        clearable
        close
        deletable-chips
        hide-selected
        item-text="name"
        item-value="id"
        multiple
        no-filter
        v-model="searchFilters"
      >
        <template v-slot:selection="{ attr, item, selected }">
          <v-menu v-model="menu" bottom right transition="scale-transition" origin="top left" >
            <template v-slot:activator="{ on }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                pill
                v-on="on"
                close
                @click:close="remove(item)"
              >
                <search-filter :filter="item" />
              </v-chip>
            </template>
          </v-menu>
        </template>

        <template slot="append-outer">
          <search-filter-create-dialog v-model="createdFilter" />
        </template>
      </v-combobox>
    </v-row>
  </v-container>
</template>

<script>
import { cloneDeep, debounce } from "lodash"

import SearchApi from "@/search/api"
import SearchUtils from "@/search/utils"
import SearchFilter from "@/search/SearchFilter.vue"
import SearchFilterCreateDialog from "@/search/SearchFilterCreateDialog.vue"

export default {
  name: "SearchFilterCombobox",
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      },
    },
    label: {
      type: String,
      default: "Search Filter",
    },
    project: {
      type: [Object],
      default: null,
    },
  },

  components: { SearchFilterCreateDialog, SearchFilter },

  data() {
    return {
      loading: false,
      items: [],
      search: null,
      createdFilter: null,
      menu: false,
    }
  },

  computed: {
    searchFilters: {
      get() {
        return cloneDeep(this.value)
      },
      set(value) {
        this.search = null
        this._filters = value.filter((v) => {
          if (typeof v === "string") {
            return false
          }
          return true
        })
        this.$emit("input", this._filters)
      },
    },
  },

  watch: {
    createdFilter: function (newVal) {
      this.items.push(newVal)
      this.searchFilters.push(newVal)
    },
  },

  methods: {
    remove(item) {
        const index = this.searchFilters.indexOf(item);
        if (index > -1) {
            // Create a copy of the array and then mutate it
            const updatedFilters = [...this.searchFilters];
            updatedFilters.splice(index, 1);

            // Emit the updated array to update the parent component
            this.$emit("input", updatedFilters);
        }
    },
    fetchData() {
      this.error = null
      this.loading = "error"
      let filterOptions = {
        q: this.search,
        itemsPerPage: 50,
        sortBy: ["name"],
        descending: [false],
      }

      if (this.project) {
        filterOptions = {
          ...filterOptions,
          filters: {
            project: [this.project],
          },
        }
        filterOptions = SearchUtils.createParametersFromTableOptions({ ...filterOptions })
      }

      SearchApi.getAllFilters(filterOptions).then((response) => {
        this.items = response.data.items
        this.loading = false
      })
    },
    getFilteredData: debounce(function (options) {
      this.fetchData(options)
    }, 500),
  },

  created() {
    this.fetchData()
    this.$watch(
      (vm) => [vm.project],
      () => {
        this.fetchData()
      }
    )
  },
}
</script>
